export type EventType = {
  duration: number;
  midiNumber: number;
  time: number;
};

export type KeyboardShortcutType = {
  key: string;
  midiNumber: number;
};

export type NoteRange = {
  first: number;
  last: number;
};

export type PendingNotesType = { [midiNumber: string]: number };

export type PianoType = {
  first_note: number;
  instrument_name: string;
  key_width_to_height: number;
  last_note: number;
  short_id: string;
  title: string;
};

export type RecordingType = {
  currentTime: number;
  duration: number;
  events: EventType[];
  bpm: number;
  version: string;
};

export type SavedPianoType = PianoType & {
  created_at: string;
};

export enum SoundfontValue {
  MusyngKite = 'MusyngKite',
  FluidR3 = 'FluidR3_GM',
}

export type UserType = {
  id: number;
  username: string;
};
