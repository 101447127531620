import React from 'react';
import { connect } from 'react-redux';
import { Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { Link, RouteComponentProps } from 'react-router-dom';
import axios from 'axios';

import Nav from 'components/Nav';
import { EmailField, PasswordField, UsernameField } from './formFields';

export interface SignupFormValues {
  email: string;
  password: string;
  passwordConfirmation: string;
  username: string;
}

function buildErrors(responseError: string) {
  let errors: FormikErrors<SignupFormValues> = {};
  if (responseError === 'MISSING_EMAIL') {
    errors.email = 'Email required';
  } else if (responseError === 'EMAIL_ALREADY_EXISTS') {
    errors.email = 'Email is already taken';
  } else if (responseError === 'MISSING_PASSWORD') {
    errors.password = 'Password required';
  } else if (responseError === 'MISSING_USERNAME') {
    errors.username = 'Username required';
  } else if (responseError === 'USERNAME_ALREADY_EXISTS') {
    errors.username = 'Username is already taken';
  }
  return errors;
}

class Signup extends React.Component<RouteComponentProps & { dispatch: Function }> {
  render() {
    return (
      <div>
        <Nav />
        <div className="container my-6">
          <div className="row">
            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
              <h2>
                <span role="img" aria-label="key">
                  ✏️
                </span>{' '}
                Sign up for PianoHub
              </h2>
              <SignupForm
                className="mt-5"
                history={this.props.history}
                dispatch={this.props.dispatch}
              />
              <div className="mt-5">
                <Link className="text-secondary" to="/login">
                  Already have an account? <strong>Login here.</strong>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class SignupForm extends React.Component<{
  history: RouteComponentProps['history'];
  className?: string;
  dispatch: Function;
}> {
  render() {
    return (
      <Formik
        initialValues={{
          email: '',
          username: '',
          password: '',
          passwordConfirmation: '',
        }}
        validate={(values: SignupFormValues) => {
          let errors: FormikErrors<SignupFormValues> = {};
          if (values.password && values.password !== values.passwordConfirmation) {
            errors.passwordConfirmation = 'Does not match password';
          }
          return errors;
        }}
        onSubmit={(values, actions: FormikActions<SignupFormValues>) => {
          axios
            .post('/api/auth/signup', values)
            .then(({ data }) => {
              this.props.dispatch({
                type: 'SET_USER',
                item: data.data,
              });
              this.props.history.push('/');
            })
            .catch(({ response }) => {
              console.error('Signup error', response);
              actions.setErrors(buildErrors(response.data.error));
            })
            .then(() => {
              actions.setSubmitting(false);
            });
        }}
        render={(formikProps: FormikProps<SignupFormValues>) => {
          return (
            <form className={this.props.className} onSubmit={formikProps.handleSubmit}>
              <EmailField name="email" {...formikProps} />
              <UsernameField name="username" {...formikProps} />
              <PasswordField name="password" label="Password" {...formikProps} />
              <PasswordField
                name="passwordConfirmation"
                label="Confirm password"
                {...formikProps}
              />
              <div className="mt-4">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={formikProps.isSubmitting || Object.keys(formikProps.errors).length > 0}
                >
                  Submit
                </button>
              </div>
            </form>
          );
        }}
      />
    );
  }
}

export default connect(null)(Signup);
