import React from 'react';
import classNames from 'classnames';

import BPMInput from './BPMInput';
import EditMenu from './EditMenu';
import KeyboardSettings from './KeyboardSettings';
import { PianoType, RecordingType } from 'types';
import { ActionMenuActions } from '../types';

enum ActionMenuItem {
  KeyboardSettings = 'KeyboardSettings',
}

type Props = {
  actions: ActionMenuActions;
  className?: string;
  disabled: boolean;
  onSave: () => void;
  piano: PianoType;
  recording: RecordingType;
  setKeyEventsEnabled: (value: boolean) => void;
  setPiano: (value: Partial<PianoType>) => void;
  setRecording: (value: Partial<RecordingType>) => void;
  style?: React.CSSProperties;
};

type State = {
  activeItem: ActionMenuItem | null;
};

class ActionMenu extends React.Component<Props, State> {
  state = {
    activeItem: null,
  };

  toggleItem = (item: ActionMenuItem) => {
    const nextActiveItem = this.state.activeItem === item ? null : item;
    this.setState({
      activeItem: nextActiveItem,
    });
  };

  render() {
    return (
      <div
        className={classNames('PianoEditor__ActionMenu m-4', this.props.className)}
        style={this.props.style}
      >
        <KeyboardSettings
          className="width-full"
          isOpen={this.state.activeItem === ActionMenuItem.KeyboardSettings}
          toggle={() => this.toggleItem(ActionMenuItem.KeyboardSettings)}
          piano={this.props.piano}
          setPiano={this.props.setPiano}
          disabled={this.props.disabled}
        />
        <div className="mt-3">
          <BPMInput
            className="width-full"
            recording={this.props.recording}
            setRecording={this.props.setRecording}
            onEdit={() => {
              this.props.setKeyEventsEnabled(false);
            }}
            onSubmit={() => {
              this.props.setKeyEventsEnabled(true);
              this.props.onSave();
            }}
            disabled={this.props.disabled}
          />
        </div>
        <div className="mt-3">
          <EditMenu
            className="width-full"
            actions={this.props.actions}
            disabled={this.props.disabled}
          />
        </div>
      </div>
    );
  }
}

export default ActionMenu;
