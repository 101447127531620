import React from 'react';
import classNames from 'classnames';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { ActionMenuActions } from '../types';

class EditMenu extends React.Component<{
  actions: ActionMenuActions;
  className?: string;
  disabled: boolean;
}> {
  state = {
    isOpen: false,
  };

  toggleIsOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { deleteAllNotes, moveCursorToStart, moveCursorToEnd } = this.props.actions;

    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this.toggleIsOpen}>
        <DropdownToggle
          className={classNames('btn btn-sm btn-outline-secondary', this.props.className)}
          caret
          disabled={this.props.disabled}
        >
          Edit
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header>Edit</DropdownItem>
          <DropdownItem className="text-danger" onClick={deleteAllNotes}>
            Delete all notes
            <span className="badge badge-secondary ml-2">shift</span> +
            <span className="badge badge-secondary ml-1">delete</span>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem header>Cursor</DropdownItem>
          <DropdownItem onClick={moveCursorToStart}>
            Move cursor to start
            <span className="badge badge-secondary ml-2">shift</span> +
            <span className="badge badge-secondary ml-1">up arrow</span>
          </DropdownItem>
          <DropdownItem onClick={moveCursorToEnd}>
            Move cursor to end
            <span className="badge badge-secondary ml-2">shift</span> +
            <span className="badge badge-secondary ml-1">down arrow</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default EditMenu;
