import React from 'react';
import { SoundfontValue } from 'types';

type Props = {
  hostname: string;
  soundfont: SoundfontValue;
  render: (instrumentList: string[] | null) => React.ReactNode;
};

type State = {
  instrumentList: string[] | null;
};

class InstrumentListProvider extends React.Component<Props, State> {
  static defaultProps = {
    soundfont: SoundfontValue.MusyngKite,
  };

  state: State = {
    instrumentList: null,
  };

  componentDidMount() {
    this.loadInstrumentList();
  }

  loadInstrumentList = () => {
    fetch(`${this.props.hostname}/${this.props.soundfont}/names.json`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          instrumentList: data,
        });
      });
  };

  render() {
    return this.props.render(this.state.instrumentList);
  }
}

export default InstrumentListProvider;
