import React from 'react';
import { Link } from 'react-router-dom';
import { getEmbed } from 'utils/embed';

import Nav from 'components/Nav';

// TODO: currently unused
// eslint-disable-next-line
function Steps() {
  return (
    <React.Fragment>
      <section className="mt-7">
        <h3 className="text-secondary">1. Write.</h3>
        <div className="GifBorder d-inline-block mt-4">
          <img src="/images/compose.gif" alt="gif of composition process" width="500" />
        </div>
      </section>
      <section className="mt-7">
        <h3 className="text-secondary">2. Edit.</h3>
        <div className="GifBorder d-inline-block mt-4">
          <img src="/images/edit.gif" alt="gif of editing process" width="500" />
        </div>
      </section>
      <section className="mt-7">
        <h3 className="text-secondary">3. Share.</h3>
        <div className="mt-4">{getEmbed('behind-the-yashmak', { aspectRatio: 0.15 })}</div>
      </section>
    </React.Fragment>
  );
}

class Homepage extends React.Component {
  render() {
    return (
      <div className="height-full d-flex flex-column">
        <Nav />
        <div className="flex-1 d-flex flex-column px-4">
          <div className="row flex-1">
            <div className="col-12 col-sm-7 col-md-6 offset-md-1 d-flex align-items-center">
              <div>
                <h2 className="font-weight-bold">Create and share music in your browser.</h2>
                <p>Write songs with your mouse, keyboard, or MIDI.</p>

                <div className="mt-5">
                  <Link className="btn btn-lg btn-primary" to="/song">
                    Create a new song
                  </Link>
                  <p className="mt-2">
                    <small className="text-muted">No registration needed.</small>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-5 d-flex align-items-center">
              <div className="mr-n4">
                <img
                  className="width-full"
                  src="/images/pianoroll-screenshot.png"
                  alt="screenshot of piano roll UI"
                  style={{ maxWidth: 450 }}
                />
              </div>
            </div>
          </div>
          <div className="row py-5">
            <div className="col-12"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Homepage;
