import React from 'react';
import queryString from 'query-string';

type EmbedOptions = {
  aspectRatio: number;
};

export function getEmbed(shortID: string, options: EmbedOptions) {
  // Responsive iframe
  // https://benmarshall.me/responsive-iframes/
  return (
    <div
      style={{
        overflow: 'hidden',
        paddingTop: `${options.aspectRatio * 100}%`,
        position: 'relative',
      }}
    >
      <iframe
        title="PianoHub embedded piano"
        src={`${process.env.REACT_APP_HOSTNAME}/embed/${shortID}?${queryString.stringify(options)}`}
        style={{
          border: 0,
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
        }}
      />
    </div>
  );
}
