export type ActionMenuActions = {
  deleteAllNotes: () => void;
  moveCursorToStart: () => void;
  moveCursorToEnd: () => void;
};

export enum Mode {
  STOPPED = 'STOPPED',
  RECORDING = 'RECORDING',
  PLAYING = 'PLAYING',
}
