import React from 'react';
import { MidiNumbers } from 'react-piano';
import classNames from 'classnames';

import { EventType, NoteRange } from 'types';

// TODO: dedupe with react-piano
const accidentalWidthRatio = 0.65;
const pitchPositions: { [pitchName: string]: number } = {
  C: 0,
  Db: 0.55,
  D: 1,
  Eb: 1.8,
  E: 2,
  F: 3,
  Gb: 3.5,
  G: 4,
  Ab: 4.7,
  A: 5,
  Bb: 5.85,
  B: 6,
};
function getAbsoluteKeyPosition(midiNumber: number): number {
  const OCTAVE_WIDTH = 7;
  const { octave, pitchName } = MidiNumbers.getAttributes(midiNumber);
  const pitchPosition = pitchPositions[pitchName];
  const octavePosition = OCTAVE_WIDTH * octave;
  return pitchPosition + octavePosition;
}
function getRelativeKeyPosition(targetNote: number, startNote: number): number {
  return getAbsoluteKeyPosition(targetNote) - getAbsoluteKeyPosition(startNote);
}

// Represents a PianoRoll event (a rectangle corresponding to a note)
function Event({
  event,
  naturalKeyWidth,
  noteRange,
  onClick,
  pixelToSecondsRatio,
  playbackTime,
  selected,
}: {
  event: EventType;
  naturalKeyWidth: number;
  noteRange: NoteRange;
  onClick?: () => void;
  pixelToSecondsRatio: number;
  playbackTime: number;
  selected: boolean;
}) {
  const height = event.duration * pixelToSecondsRatio;
  const positionStyle = {
    bottom: (event.time - playbackTime) * pixelToSecondsRatio,
  };
  const { isAccidental } = MidiNumbers.getAttributes(event.midiNumber);
  const width = isAccidental ? naturalKeyWidth * accidentalWidthRatio : naturalKeyWidth;
  const offsetX = getRelativeKeyPosition(event.midiNumber, noteRange.first);
  const posX = offsetX * naturalKeyWidth;

  return (
    <div
      className={classNames(
        'PianoRoll__Event position-absolute',
        isAccidental ? 'PianoRoll__Event--accidental z-index-1' : null,
        selected ? 'PianoRoll__Event--selected' : null,
      )}
      onClick={onClick}
      style={{
        left: posX,
        width: width,
        height,
        ...positionStyle,
      }}
    />
  );
}

export default Event;
