import React from 'react';
import classNames from 'classnames';

// A text node that, when clicked on, becomes an editable input.
class ClickableInput extends React.Component<
  React.InputHTMLAttributes<HTMLInputElement> & {
    className?: string;
    onEdit: () => void;
    onSubmit: () => void;
  }
> {
  state = {
    isEditing: false,
  };
  inputRef = React.createRef<HTMLInputElement>();

  onEdit = () => {
    this.props.onEdit();
    this.setState(
      {
        isEditing: true,
      },
      () => {
        // Select all text in input
        if (this.inputRef.current) {
          this.inputRef.current.select();
        }
      },
    );
  };

  onSubmit = () => {
    this.props.onSubmit();
    this.setState({
      isEditing: false,
    });
  };

  render() {
    const { onEdit, children, className, ...inputProps } = this.props;

    return this.state.isEditing ? (
      <form onSubmit={this.onSubmit}>
        <input
          className={classNames('form-control', this.props.className)}
          type="text"
          onBlur={this.onSubmit} // Override onBlur
          ref={this.inputRef}
          {...inputProps}
        />
      </form>
    ) : (
      <span onClick={this.onEdit}>{this.props.children}</span>
    );
  }
}

export default ClickableInput;
