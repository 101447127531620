import { combineReducers } from 'redux';

type State = {
  isFetching: boolean;
  didInvalidate: boolean;
  item: any;
};

export default combineReducers({
  isLoggedIn: (state = null, action) => {
    switch (action.type) {
      case 'SET_USER': {
        // Is logged in if setting user to a non-null value
        return action.item !== null;
      }
      case 'SET_NOT_LOGGED_IN': {
        return false;
      }
      default: {
        return state;
      }
    }
  },
  user: (
    state: State = {
      isFetching: false,
      didInvalidate: false,
      item: null,
    },
    action,
  ) => {
    switch (action.type) {
      case 'SET_USER': {
        return {
          ...state,
          isFetching: false,
          didInvalidate: false,
          item: action.item,
        };
      }
      default: {
        return state;
      }
    }
  },
});
