import React from 'react';
import { useMediaQuery } from 'react-responsive';

export const SmDown: React.FC = ({ children }) => {
  const shouldRender = useMediaQuery({ maxWidth: 767 });
  return shouldRender ? <div>{children}</div> : null;
};
export const MdUp: React.FC = ({ children }) => {
  const shouldRender = useMediaQuery({ minWidth: 768 });
  return shouldRender ? <div>{children}</div> : null;
};
