import React from 'react';
import { MdKeyboard, MdMouse } from 'react-icons/md';
import { GiPianoKeys } from 'react-icons/gi';

const ICON_HEIGHT_RATIOS = {
  keyboard: 30,
  mouse: 20,
  pianoKeys: 25,
};

function TutorialOverlay() {
  const iconScaling = 1.5;
  return (
    <div className="text-secondary">
      <div className="text-center" style={{ color: '#aaa' }}>
        <MdKeyboard style={{ fontSize: ICON_HEIGHT_RATIOS.keyboard * iconScaling }} />
        <MdMouse className="ml-3" style={{ fontSize: ICON_HEIGHT_RATIOS.mouse * iconScaling }} />
        <GiPianoKeys
          className="ml-3"
          style={{ fontSize: ICON_HEIGHT_RATIOS.pianoKeys * iconScaling }}
        />
      </div>
      <p className="text-center mt-3">Use keyboard, mouse, or MIDI input to play the piano.</p>
      <div className="row mt-6">
        <div className="col-6 text-right">Start and stop recording</div>
        <div className="col-6">
          <span className="ShortcutIcon">enter</span>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6 text-right">Start and stop playback</div>
        <div className="col-6">
          <span className="ShortcutIcon">spacebar</span>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6 text-right">Save song</div>
        <div className="col-6">
          <span className="ShortcutIcon">cmd</span> + <span className="ShortcutIcon">s</span>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6 text-right">Move keyboard shortcuts</div>
        <div className="col-6">
          <span className="ShortcutIcon">←</span> <span className="ShortcutIcon">→</span>
        </div>
      </div>
      <div style={{ height: 100 }} />
    </div>
  );
}

export default TutorialOverlay;
