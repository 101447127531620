import React from 'react';
import { KeyboardShortcuts, MidiNumbers } from 'react-piano';

import { KeyboardShortcutType, NoteRange } from 'types';

type Props = {
  noteRange: NoteRange;
  naturalNoteOffset: number;
  children: (keyboardShortcuts: KeyboardShortcutType[]) => React.ReactNode;
};

type State = {
  naturalNoteOffset: number;
};

// Shift note range by a number of natural notes.
// Example:
// shiftNoteRangeByNaturalOffset({ first: 'c4', last: 'c5' }, 2)
// => { first: 'e4', last: 'e5' }
function shiftNoteRangeByNaturalOffset(noteRange: NoteRange, naturalOffset: number): NoteRange {
  const noteCount = noteRange.last - noteRange.first;
  const firstNoteIndex = MidiNumbers.NATURAL_MIDI_NUMBERS.indexOf(noteRange.first);
  const firstNotePlusOffsetIndex = firstNoteIndex + naturalOffset;
  const first = MidiNumbers.NATURAL_MIDI_NUMBERS[firstNotePlusOffsetIndex];
  return {
    first,
    last: first + noteCount,
  };
}

class KeyboardShortcutsProvider extends React.Component<Props, State> {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      naturalNoteOffset: props.naturalNoteOffset || 0,
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  getKeyboardShortcuts(naturalNoteOffset: number): KeyboardShortcutType[] {
    const { first, last } = shiftNoteRangeByNaturalOffset(this.props.noteRange, naturalNoteOffset);
    return KeyboardShortcuts.create({
      firstNote: first,
      lastNote: last,
      keyboardConfig: KeyboardShortcuts.HOME_ROW,
    });
  }

  onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      this.setState({
        naturalNoteOffset: Math.max(this.state.naturalNoteOffset - 1, 0),
      });
    } else if (event.key === 'ArrowRight') {
      const numShortcuts = this.getKeyboardShortcuts(this.state.naturalNoteOffset + 1).length;
      const { first } = shiftNoteRangeByNaturalOffset(
        this.props.noteRange,
        this.state.naturalNoteOffset + 1,
      );
      // If shifting the shortcuts again would cause the last shortcut to go out of range, stop.
      // -1 because first note "consumes" a shortcut
      if (first + numShortcuts - 1 > this.props.noteRange.last) {
        return;
      }

      this.setState({
        naturalNoteOffset: this.state.naturalNoteOffset + 1,
      });
    }
  };

  render() {
    const keyboardShortcuts = this.getKeyboardShortcuts(this.state.naturalNoteOffset);
    return this.props.children(keyboardShortcuts);
  }
}

export default KeyboardShortcutsProvider;
