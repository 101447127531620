import axios from 'axios';

import { PianoType, RecordingType } from 'types';

function serializePiano({ piano, recording }: { piano: PianoType; recording: RecordingType }) {
  const serializedRecording = Object.assign({}, recording, {
    currentTime: recording.duration,
  });

  // Merge piano data with recording data
  return Object.assign({}, piano, {
    recording_data: JSON.stringify(serializedRecording),
  });
}

export default {
  fetch: (shortID: string) => {
    return axios.get(`/api/pianos/${shortID}`).then(({ data }) => {
      return {
        piano: data.data,
        recording: data.data.recording_data,
      };
    });
  },
  create: ({ piano, recording }: { piano: PianoType; recording: RecordingType }) => {
    return axios.post('/api/pianos', serializePiano({ piano, recording }));
  },
  update: ({ piano, recording }: { piano: PianoType; recording: RecordingType }) => {
    const shortID = piano.short_id;
    return axios.put(`/api/pianos/${shortID}`, serializePiano({ piano, recording }));
  },
};
