import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Piano } from 'react-piano';
import { MdPlayArrow } from 'react-icons/md';
import classNames from 'classnames';
import queryString from 'query-string';

import SoundfontProvider from 'components/SoundfontProvider';
import { Mode } from 'features/PianoEditor/types';
import { RecordingType, PianoType } from 'types';
import { SOUNDFONT_HOSTNAME, audioContext } from 'utils/audio';
import pianoActions from 'utils/pianoActions';
import { formatDuration, PlaybackState, playRecording } from 'utils/recording';

type RouteParams = {
  shortID: string;
};

type URLParams = {
  height: string;
  width: string;
};

type Props = RouteComponentProps<RouteParams> & {
  className?: string;
};

type State = {
  activeNotes: number[] | null;
  mode: Mode;
  piano: PianoType | null;
  recording: RecordingType | null;
  stopAllNotes: () => void;
};

class PianoEmbed extends React.Component<Props, State> {
  state: State = {
    piano: null,
    recording: null,
    stopAllNotes: () => {},
    activeNotes: null,
    mode: Mode.STOPPED,
  };

  playbackState: PlaybackState | null = null;

  componentDidMount() {
    this.fetchPiano();
  }

  fetchPiano = () => {
    const shortID = this.getShortID();
    if (shortID) {
      pianoActions
        .fetch(shortID)
        .then(({ piano, recording }) => {
          this.setState({
            piano,
            recording,
          });
        })
        .catch((error) => {
          console.error('PianoEmbed fetch error', error);
          // TODO: throw error and add error boundary
        });
    } else {
      throw new Error('PIANO_EMBED_MISSING_SHORT_ID');
    }
  };

  getOptions = () => {
    const params = queryString.parse(window.location.search) as URLParams;
    return {
      width: parseInt(params.width, 10),
      height: parseInt(params.height, 10),
    };
  };

  getShortID = () => {
    return this.props.match.params.shortID;
  };

  isPlaying = () => {
    return this.state.mode === Mode.PLAYING;
  };

  play = () => {
    if (!this.state.recording) {
      console.warn('play() called before state.recording set');
      return;
    }
    this.playbackState = playRecording({
      recording: this.state.recording,
      setActiveNotes: (activeNotes) => {
        this.setState({ activeNotes });
      },
      stopAllNotes: this.state.stopAllNotes,
      onStart: () => {
        this.setState({
          mode: Mode.PLAYING,
        });
      },
      onEnd: () => {
        this.setState({
          mode: Mode.STOPPED,
        });
      },
    });
  };

  stop = () => {
    if (this.playbackState) {
      this.playbackState.stop();
      this.playbackState = null;
    }
    this.setState({
      mode: Mode.STOPPED,
    });
  };

  togglePlayback = () => {
    if (this.isPlaying()) {
      this.stop();
    } else {
      this.play();
    }
  };

  render() {
    if (!(this.state.piano && this.state.recording)) {
      return null;
    }
    const noteRange = {
      first: this.state.piano.first_note,
      last: this.state.piano.last_note,
    };
    const duration = this.state.recording.duration;
    return (
      <div
        className={classNames(
          'PianoEmbed bg-transparent height-full position-relative',
          this.props.className,
        )}
      >
        <div
          className={classNames('position-absolute width-full height-full z-index-2')}
          onClick={this.togglePlayback}
        >
          {this.isPlaying() ? (
            <div className="PianoEmbed__Overlay PianoEmbed__Overlay--hidden" />
          ) : (
            <div className="PianoEmbed__Overlay">
              <div className="flex-1 align-self-end mr-2">
                <a
                  className="text-white small font-weight-bold"
                  href={`${process.env.REACT_APP_HOSTNAME}/song/${this.getShortID()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  // Prevent togglePlayback
                  onClick={(event) => event.stopPropagation()}
                >
                  <span role="img" aria-label="piano">
                    🎹
                  </span>
                  PianoHub
                </a>
              </div>
              <div className="text-white flex-1 d-flex flex-column align-items-center justify-content-center">
                <div className="h4 mb-0">
                  <MdPlayArrow /> Play
                </div>
                <small className="opacity-75">
                  {`${this.state.piano.title} (${formatDuration(duration)})`}
                </small>
              </div>
              <div className="flex-1">&nbsp;</div>
            </div>
          )}
        </div>
        <SoundfontProvider
          instrumentName={this.state.piano.instrument_name}
          audioContext={audioContext}
          hostname={SOUNDFONT_HOSTNAME}
          onLoad={({ stopAllNotes }) => this.setState({ stopAllNotes })}
          render={({ isLoading, playNote, stopNote, stopAllNotes }) => (
            <div style={{ width: '100%', height: '100%' }}>
              <Piano
                noteRange={noteRange}
                activeNotes={this.state.activeNotes}
                playNote={playNote}
                stopNote={stopNote}
                disabled={isLoading}
              />
            </div>
          )}
        />
      </div>
    );
  }
}

export default PianoEmbed;
