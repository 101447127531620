import React from 'react';
import { MdEdit } from 'react-icons/md';
import classNames from 'classnames';
import ClickableInput from 'components/ClickableInput';
import { RecordingType } from 'types';

class BPMInput extends React.Component<{
  className?: string;
  disabled: boolean;
  onEdit: () => void;
  onSubmit: () => void;
  recording: RecordingType;
  setRecording: (value: Partial<RecordingType>) => void;
}> {
  render() {
    return (
      <ClickableInput
        className={this.props.className}
        value={this.props.recording.bpm}
        onEdit={this.props.onEdit}
        onChange={(event) => {
          const MAX_BPM = 1200;
          const MIN_BPM = 1;
          const bpm = parseInt(event.target.value, 10);
          if (isNaN(bpm) || bpm > MAX_BPM || bpm < MIN_BPM) {
            alert(`BPM must be between ${MIN_BPM} and ${MAX_BPM}`);
            return;
          }
          this.props.setRecording({
            bpm: bpm,
          });
        }}
        onSubmit={this.props.onSubmit}
      >
        <button
          className={classNames('btn btn-sm btn-outline-secondary', this.props.className)}
          disabled={this.props.disabled}
        >
          <MdEdit /> BPM {this.props.recording.bpm}
        </button>
      </ClickableInput>
    );
  }
}

export default BPMInput;
