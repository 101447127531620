import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { MidiNumbers } from 'react-piano';

import { getPlaybackTimeOffset, PlaybackState } from 'utils/recording';
import { NoteRange, RecordingType, PendingNotesType, EventType } from 'types';
import Event from './Event';
import { Mode } from './types';

// TODO: dedupe key position logic with Keyboard.js, Key.js (have it be exported by react-piano?
function getNaturalKeyCount(noteRange: NoteRange): number {
  const midiNumbers = _.range(noteRange.first, noteRange.last + 1);
  return midiNumbers.filter((number) => {
    return !MidiNumbers.getAttributes(number).isAccidental;
  }).length;
}

const PIXELS_PER_UNIT_TIME = 100;

type Rectangle = {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};

type Props = {
  className?: string;
  height: number;
  mode: Mode;
  noteRange: NoteRange;
  pendingNotes: PendingNotesType;
  playbackState: PlaybackState | null;
  recording: RecordingType;
  recordingStartTime: number;
  setRecording: (value: Partial<RecordingType>) => void;
  width: number;
};

type State = {
  playbackTime: number;
  selectedEvents: EventType[];
  selectedBox: Rectangle | null;
};

// PianoRoll renders a visualization of the recorded notes
// in a player-piano style timeline of events.
class PianoRoll extends React.Component<Props, State> {
  state: State = {
    playbackTime: 0,
    selectedEvents: [],
    selectedBox: null,
  };

  // Reference returned from window.requestAnimationFrame calls,
  // to be able to cancel with cancelAnimationFrame.
  updatePlaybackTimeRAF: number | null = null;
  updatePlaybackTimeForRecordingRAF: number | null = null;

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.mode !== Mode.PLAYING && this.props.mode === Mode.PLAYING) {
      // Animate for playback
      this.updatePlaybackTimeRAF = window.requestAnimationFrame(this.updatePlaybackTime);
    } else if (prevProps.mode !== Mode.RECORDING && this.props.mode === Mode.RECORDING) {
      // Animate for recording
      this.updatePlaybackTimeForRecordingRAF = window.requestAnimationFrame(
        this.updatePlaybackTimeForRecording,
      );
    } else if (prevProps.mode !== Mode.STOPPED && this.props.mode === Mode.STOPPED) {
      // Stop animating
      this.setState({
        playbackTime: 0,
      });
      if (this.updatePlaybackTimeRAF) {
        window.cancelAnimationFrame(this.updatePlaybackTimeRAF);
      }
      if (this.updatePlaybackTimeForRecordingRAF) {
        window.cancelAnimationFrame(this.updatePlaybackTimeForRecordingRAF);
      }
    }
  }

  isPlaying = () => {
    return this.props.mode === Mode.PLAYING;
  };

  isRecording = () => {
    return this.props.mode === Mode.RECORDING;
  };

  isStopped = () => {
    return this.props.mode === Mode.STOPPED;
  };

  // Update playbackTime on every frame, which causes PianoRoll to animate
  updatePlaybackTime = (animationTimeMillis: number) => {
    if (!this.props.playbackState) {
      throw new Error('playbackState not set when updatePlaybackTime called');
    }
    const animationTime = animationTimeMillis / 1000;
    // Stop animating once end time is reached
    if (animationTime > this.props.playbackState.playbackEndTime) {
      return;
    }

    const timeElapsed = animationTime - this.props.playbackState.playbackStartTime;
    // This is used to start playback at the cursor (currentTime)
    const playbackTime = getPlaybackTimeOffset(this.props.recording) + timeElapsed;

    // Update playbackTime state
    this.setState({
      playbackTime,
    });

    // Continue animating by calling requestAnimationFrame again
    this.updatePlaybackTimeRAF = window.requestAnimationFrame(this.updatePlaybackTime);
  };

  updatePlaybackTimeForRecording = (animationTimeMillis: number) => {
    const animationTime = animationTimeMillis / 1000;

    // NOTE: this compares RAF timestamp to window.performance.now timestamp
    const timeElapsed = animationTime - this.props.recordingStartTime;

    this.setState({
      playbackTime: timeElapsed,
    });

    this.updatePlaybackTimeForRecordingRAF = window.requestAnimationFrame(
      this.updatePlaybackTimeForRecording,
    );
  };

  setCursor = (event: React.MouseEvent) => {
    // Get Y coordinate within potentially scrolled div
    const rect = event.currentTarget.getBoundingClientRect();
    const distanceFromTop = event.clientY - rect.top;
    const timeFromTop = distanceFromTop / PIXELS_PER_UNIT_TIME;
    this.props.setRecording({
      currentTime: this.props.recording.duration - timeFromTop,
    });
  };

  selectEvents = (events: EventType[]) => {
    if (!this.isStopped()) {
      console.warn('Called selectEvents in non-stopped mode');
      return;
    }
    this.setState({
      selectedEvents: events,
    });
  };

  handleMouseDown = (event: React.MouseEvent) => {
    const targetRect = event.currentTarget.getBoundingClientRect();
    const x1 = event.clientX - targetRect.left;
    const y1 = event.clientY - targetRect.top;
    this.setState({
      selectedBox: {
        x1,
        y1,
        x2: x1,
        y2: y1,
      },
    });
  };

  handleMouseMove = (event: React.MouseEvent) => {
    if (!this.state.selectedBox) {
      return;
    }
    const targetRect = event.currentTarget.getBoundingClientRect();
    const x2 = event.clientX - targetRect.left;
    const y2 = event.clientY - targetRect.top;
    this.setState({
      selectedBox: Object.assign({}, this.state.selectedBox, {
        x2,
        y2,
      }),
    });
  };

  handleMouseUp = (event: React.MouseEvent) => {
    if (!this.state.selectedBox) {
      return;
    }
    // Only set cursor if box has size 0
    const { x1, y1, x2, y2 } = this.state.selectedBox;
    if (x1 === x2 && y1 === y2) {
      this.setCursor(event);
    }
    // Remove box
    this.setState({
      selectedBox: null,
    });
  };

  render() {
    const naturalKeyCount = getNaturalKeyCount(this.props.noteRange);
    const naturalKeyWidth = this.props.width / naturalKeyCount;
    const isRecording = this.isRecording();
    const isPlaying = this.isPlaying();
    const isStopped = this.isStopped();
    const height = this.props.height;
    const now = window.performance.now() / 1000;

    return (
      <div className={this.props.className}>
        {/* Outer container constrains height with scrolling */}
        <div
          id="PianoRoll"
          className={classNames({
            // Align inner container to top when recording, and bottom when playing
            'd-flex align-items-start': isRecording,
            'd-flex align-items-end': isPlaying,
          })}
          style={{
            height: height,
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          {/* Inner container: full height canvas for absolute-positioned events */}
          <div
            className="position-relative width-full d-flex align-items-end"
            style={{
              height: this.props.recording.duration * PIXELS_PER_UNIT_TIME,
            }}
            onMouseDown={this.handleMouseDown}
            onMouseMove={this.handleMouseMove}
            onMouseUp={this.handleMouseUp}
          >
            {this.props.recording.events.map((event) => (
              <Event
                event={event}
                key={`${event.midiNumber},${event.time}`}
                naturalKeyWidth={naturalKeyWidth}
                noteRange={this.props.noteRange}
                onClick={() => this.selectEvents([event])}
                pixelToSecondsRatio={PIXELS_PER_UNIT_TIME}
                playbackTime={this.state.playbackTime}
                selected={isStopped ? this.state.selectedEvents.includes(event) : false}
              />
            ))}
            {/* Pending notes */}
            {Object.keys(this.props.pendingNotes).length > 0
              ? Object.keys(this.props.pendingNotes).map((midiStr: string) => {
                  const midiNumber = Number(midiStr);
                  const absoluteStartTime = this.props.pendingNotes[midiStr] as number;
                  const absoluteEndTime = now;
                  const duration = absoluteEndTime - absoluteStartTime;
                  const relativeStartTime =
                    absoluteStartTime -
                    this.props.recordingStartTime +
                    this.props.recording.currentTime;
                  const event = {
                    midiNumber,
                    time: relativeStartTime,
                    duration,
                  };
                  return (
                    <Event
                      event={event}
                      key={`${event.midiNumber},${event.time}`}
                      naturalKeyWidth={naturalKeyWidth}
                      noteRange={this.props.noteRange}
                      pixelToSecondsRatio={PIXELS_PER_UNIT_TIME}
                      playbackTime={this.state.playbackTime}
                      selected={isStopped ? this.state.selectedEvents.includes(event) : false}
                    />
                  );
                })
              : null}
            {isStopped ? <Cursor currentTime={this.props.recording.currentTime} /> : null}
            {this.state.selectedBox ? <SelectionBox rect={this.state.selectedBox} /> : null}
          </div>
        </div>
      </div>
    );
  }
}

function Cursor({ currentTime }: { currentTime: number }) {
  return (
    <div
      className="PianoRoll__Cursor position-absolute"
      style={{
        bottom: currentTime * PIXELS_PER_UNIT_TIME,
      }}
    />
  );
}

function SelectionBox({ rect }: { rect: Rectangle }) {
  return (
    <div
      className="position-absolute"
      style={{
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        border: '1px dashed #fff',
        borderRadius: 2,
        top: Math.min(rect.y1, rect.y2),
        left: Math.min(rect.x1, rect.x2),
        width: Math.abs(rect.x2 - rect.x1),
        height: Math.abs(rect.y2 - rect.y1),
      }}
    />
  );
}

export default PianoRoll;
