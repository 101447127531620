import React from 'react';
import classNames from 'classnames';
import { FormikProps } from 'formik';

function EmailField<T>({
  name,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
}: FormikProps<T> & {
  name: keyof T;
}) {
  const showError = errors[name] && touched[name];

  return (
    <div className="form-group">
      <label htmlFor={name as string}>Email</label>
      <input
        className={classNames('form-control', { 'is-invalid': showError })}
        type="email"
        name={name as string}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name] as any}
      />
      {showError ? <div className="invalid-feedback d-block">{errors[name]}</div> : null}
    </div>
  );
}

function PasswordField<T>({
  name,
  label,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
}: FormikProps<T> & {
  name: keyof T;
  label: string;
}) {
  const showError = errors[name] && touched[name];

  return (
    <div className="form-group">
      <label htmlFor={name as string}>{label}</label>
      <input
        className={classNames('form-control', { 'is-invalid': showError })}
        type="password"
        name={name as string}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name] as any}
      />
      {showError ? <div className="invalid-feedback d-block">{errors[name]}</div> : null}
    </div>
  );
}

function UsernameField<T>({
  name,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
}: FormikProps<T> & {
  name: keyof T;
}) {
  const showError = errors[name] && touched[name];

  return (
    <div className="form-group">
      <label htmlFor={name as string}>Username</label>
      <input
        className={classNames('form-control', { 'is-invalid': showError })}
        type="text"
        name={name as string}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name] as any}
      />
      <div className="text-secondary">
        <small>
          This will be your public name. <strong>{`pianohub.io/user/${values[name]}`}</strong>
        </small>
      </div>
      {showError ? <div className="invalid-feedback d-block">{errors[name]}</div> : null}
    </div>
  );
}

export { EmailField, PasswordField, UsernameField };
