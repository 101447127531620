// See https://github.com/danigb/soundfont-player
// for more documentation on prop options.
import React from 'react';
import Soundfont from 'soundfont-player';

import { SoundfontValue } from 'types';

enum FileFormat {
  mp3 = 'mp3',
  ogg = 'ogg',
}

type RenderProps = {
  isLoading: boolean;
  playNote: (midiNumber: number) => void;
  stopNote: (midiNumber: number) => void;
  stopAllNotes: () => void;
};

type Props = {
  audioContext: AudioContext;
  format: FileFormat;
  hostname: string;
  instrumentName: string;
  onLoad: (args: RenderProps) => void;
  render: (args: RenderProps) => React.ReactNode;
  soundfont: SoundfontValue;
};

type State = {
  activeAudioNodes: { [midiNumber: string]: AudioNodeType };
  instrument: InstrumentType | null;
};

interface InstrumentType {
  name: string;
  play: (midiNumber: number) => AudioNodeType;
}

interface AudioNodeType {
  stop: () => void;
}

class SoundfontProvider extends React.Component<Props, State> {
  static defaultProps = {
    format: 'mp3',
    soundfont: 'MusyngKite',
    instrumentName: 'acoustic_grand_piano',
  };

  state: State = {
    activeAudioNodes: {},
    instrument: null,
  };

  componentDidMount() {
    this.loadInstrument(this.props.instrumentName);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.instrumentName !== this.props.instrumentName) {
      this.loadInstrument(this.props.instrumentName);
    }

    if (prevState.instrument !== this.state.instrument) {
      if (!this.props.onLoad) {
        return;
      }
      this.props.onLoad({
        isLoading: !this.state.instrument,
        playNote: this.playNote,
        stopNote: this.stopNote,
        stopAllNotes: this.stopAllNotes,
      });
    }
  }

  loadInstrument = (instrumentName: string) => {
    // Re-trigger loading state
    this.setState({
      instrument: null,
    });
    Soundfont.instrument(this.props.audioContext, instrumentName, {
      format: this.props.format,
      soundfont: this.props.soundfont,
      nameToUrl: (name: string, soundfont: SoundfontValue, format: FileFormat) => {
        return `${this.props.hostname}/${soundfont}/${name}-${format}.js`;
      },
    }).then((instrument: InstrumentType) => {
      // This is to prevent race condition where instrumentName quickly
      // changes. loadInstrument is called twice in succession:
      //
      // loadInstrument('piano')
      // loadInstrument('guitar')
      //
      // If 'guitar' resolves before 'piano' does, then the wrong instrument
      // will be set.
      if (instrument.name === this.props.instrumentName) {
        this.setState({
          instrument,
        });
      }
    });
  };

  playNote = (midiNumber: number) => {
    this.resumeAudio().then(() => {
      if (!this.state.instrument) {
        console.warn('Called playNote when not fully loaded');
        return;
      }
      const audioNode = this.state.instrument.play(midiNumber);
      this.setState({
        activeAudioNodes: Object.assign({}, this.state.activeAudioNodes, {
          [midiNumber]: audioNode,
        }),
      });
    });
  };

  stopNote = (midiNumber: number) => {
    this.resumeAudio().then(() => {
      if (!this.state.activeAudioNodes[midiNumber]) {
        return;
      }
      const audioNode = this.state.activeAudioNodes[midiNumber];
      audioNode.stop();
      this.setState({
        activeAudioNodes: Object.assign({}, this.state.activeAudioNodes, { [midiNumber]: null }),
      });
    });
  };

  resumeAudio = () => {
    if (this.props.audioContext.state === 'suspended') {
      return this.props.audioContext.resume();
    } else {
      return Promise.resolve();
    }
  };

  // Clear any residual notes that don't get called with stopNote
  stopAllNotes = () => {
    this.props.audioContext.resume().then(() => {
      const activeAudioNodes = Object.values(this.state.activeAudioNodes);
      activeAudioNodes.forEach((node) => {
        if (node) {
          node.stop();
        }
      });
      this.setState({
        activeAudioNodes: {},
      });
    });
  };

  render() {
    return this.props.render
      ? this.props.render({
          isLoading: !this.state.instrument,
          playNote: this.playNote,
          stopNote: this.stopNote,
          stopAllNotes: this.stopAllNotes,
        })
      : null;
  }
}

export default SoundfontProvider;
