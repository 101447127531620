import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import axios from 'axios';

// Styles
import 'react-piano/dist/styles.css';
import './App.scss';

import Homepage from 'features/Homepage';
import { Login, Signup } from 'features/Login';
import PianoEditor from 'features/PianoEditor';
import PianoList from 'features/PianoList';
import PianoEmbed from 'features/PianoEmbed';
import PianoShare from 'features/PianoShare';

class App extends React.Component<{
  dispatch: Function;
  isLoggedIn: boolean | null;
}> {
  componentDidMount() {
    axios
      .get('/api/user')
      .then(({ data }) => {
        this.props.dispatch({
          type: 'SET_USER',
          item: data.data,
        });
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          // Not logged in
          this.props.dispatch({ type: 'SET_NOT_LOGGED_IN' });
        } else {
          console.error('Error fetching user', response);
        }
      });
  }

  render() {
    // Loading
    if (this.props.isLoggedIn === null) {
      return null;
    }
    return (
      <Router>
        <div className="RouterContainer">
          <Route exact path="/" component={this.props.isLoggedIn ? PianoList : Homepage} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/song" component={PianoEditor} />
          <Route exact path="/song/:shortID" component={PianoEditor} />
          <Route exact path="/embed/:shortID" component={PianoEmbed} />
          <Route exact path="/share/:shortID" component={PianoShare} />
        </div>
      </Router>
    );
  }
}

export default connect((state: any) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
})(App);
