import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Link, RouteComponentProps } from 'react-router-dom';

import Nav from 'components/Nav';
import { getEmbed } from 'utils/embed';
import pianoActions from 'utils/pianoActions';
import { PianoType } from 'types';

type RouteParams = {
  shortID: string;
};

type Props = RouteComponentProps<RouteParams> & {};

type State = {
  keyWidthToHeightRatio: number;
  piano: PianoType | null;
};

function getNumKeys(piano: PianoType) {
  return piano.last_note - piano.first_note + 1;
}

class PianoShare extends React.Component<Props, State> {
  state: State = {
    keyWidthToHeightRatio: 0.25,
    piano: null,
  };

  componentDidMount() {
    pianoActions.fetch(this.getShortID()).then(({ piano }) => {
      this.setState({
        piano,
      });
    });
  }

  getShortID = () => {
    return this.props.match.params.shortID;
  };

  getAspectRatio = (piano: PianoType) => {
    // height = h
    // single key width = keyWidthToHeightRatio * h
    // width = keyWidthToHeightRatio * h * numKeys
    // aspectRatio = height / width = 1 / (keyWidthToHeightRatio * numKeys)
    return 1 / (this.state.keyWidthToHeightRatio * getNumKeys(piano));
  };

  render() {
    const shortID = this.getShortID();
    const embedContent = this.state.piano
      ? getEmbed(shortID, { aspectRatio: this.getAspectRatio(this.state.piano) })
      : null;
    const directLink = `${process.env.REACT_APP_HOSTNAME}/song/${this.getShortID()}`;
    return (
      <div>
        <Nav />
        <div className="container my-6">
          <h2>
            <span role="img" aria-label="share emoji">
              🎁
            </span>
            <span className="ml-2">Share</span>
          </h2>
          <h3 className="mt-5">Direct link</h3>
          <p className="mt-4">Recipients will be able to view but not edit your song.</p>
          <div className="text-secondary p-3 bg-light-gray">
            <a href={directLink}>{directLink}</a>
          </div>
          <div className="mt-4">
            <Link className="btn btn-secondary" to={`/song/${shortID}`}>
              Edit piano
            </Link>
          </div>

          <h3 className="mt-5">Embed</h3>
          <div className="mt-4">
            <p>
              Copy and paste this wherever HTML input is allowed &mdash; Wordpress or Medium, for
              example &mdash; to add an embedded player:
            </p>
            <pre className="p-3 bg-light-gray">
              {embedContent ? renderToStaticMarkup(embedContent) : null}
            </pre>
          </div>
          <div className="mt-4">
            <p>Preview:</p>
            <div>{embedContent}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default PianoShare;
