import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import timeago from 'timeago.js';

import Nav from 'components/Nav';
import { SavedPianoType, UserType } from 'types';

const timeagoInstance = timeago();

function sortListByMostRecent(list: SavedPianoType[]) {
  return _.sortBy(list, (elem) => Date.parse(elem.created_at)).reverse();
}

function EmptyList() {
  return (
    <div
      className="text-center"
      style={{
        padding: '100px 0',
        border: '1px solid #888',
        borderStyle: 'dashed',
        borderRadius: 5,
      }}
    >
      <h2 className="h4">You haven’t created any songs yet.</h2>{' '}
      <div className="mt-4">
        <Link className="btn btn-primary" to="/song">
          Create one now
        </Link>
      </div>
    </div>
  );
}

function PianoListItem({ piano }: { piano: SavedPianoType }) {
  return (
    <div className="mb-1" key={piano.short_id}>
      <Link to={`/song/${piano.short_id}`}>
        <span className="d-flex py-4">
          <span className="flex-1">{piano.title}</span>
          <span className="text-secondary">{timeagoInstance.format(piano.created_at)}</span>
        </span>
      </Link>
      <hr className="my-0" />
    </div>
  );
}

type PianoListProps = {
  user: UserType;
};

type PianoListState = {
  list: SavedPianoType[] | null;
};

class PianoList extends React.Component<PianoListProps, PianoListState> {
  state: PianoListState = {
    list: null,
  };

  componentDidMount() {
    this.fetchList();
  }

  componentDidUpdate() {
    this.fetchList();
  }

  fetchList = () => {
    if (this.props.user && !this.state.list) {
      axios.get('/api/pianos', { params: { user_id: this.props.user.id } }).then(({ data }) => {
        this.setState({
          list: sortListByMostRecent(data.data),
        });
      });
    }
  };

  render() {
    // Loading
    const { list } = this.state;
    if (list === null) {
      return null;
    }
    return (
      <div>
        <Nav />
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
              {list.length > 0 ? (
                <div>
                  <h1 className="h4 font-weight-bold mb-4">Songs</h1>
                  {list.map((piano) => (
                    <PianoListItem piano={piano} />
                  ))}
                </div>
              ) : (
                <EmptyList />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state: any) => {
  return {
    user: state.user.item,
  };
})(PianoList);
