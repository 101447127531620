import React from 'react';
import { MdSettings } from 'react-icons/md';
import { MidiNumbers } from 'react-piano';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import classNames from 'classnames';

import InstrumentListProvider from 'components/InstrumentListProvider';
import { SOUNDFONT_HOSTNAME } from 'utils/audio';
import { PianoType } from 'types';

function Label({ children }: { children: React.ReactNode }) {
  return <small className="mb-1 text-muted">{children}</small>;
}

class KeyboardSettings extends React.Component<{
  className?: string;
  disabled: boolean;
  isOpen: boolean;
  piano: PianoType;
  setPiano: (value: Partial<PianoType>) => void;
  toggle: () => void;
}> {
  onChangeFirstNote = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.setPiano({
      first_note: parseInt(event.target.value, 10),
    });
  };

  onChangeLastNote = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.setPiano({
      last_note: parseInt(event.target.value, 10),
    });
  };

  onChangeInstrument = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.setPiano({
      instrument_name: event.target.value,
    });
  };

  render() {
    const midiNumbersToNotes = MidiNumbers.NATURAL_MIDI_NUMBERS.reduce(
      (obj: { [midiNumber: string]: string }, midiNumber: number) => {
        obj[midiNumber] = MidiNumbers.getAttributes(midiNumber).note;
        return obj;
      },
      {},
    );

    return (
      <div>
        <button
          className={classNames('btn btn-sm btn-outline-secondary', this.props.className)}
          onClick={this.props.toggle}
          disabled={this.props.disabled}
        >
          <MdSettings /> Keyboard
        </button>
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
          <ModalHeader>
            <MdSettings /> Keyboard settings
          </ModalHeader>
          <ModalBody>
            <div className="form-row">
              <div className="col-6">
                <Label>First note</Label>
                <select
                  className="form-control"
                  onChange={this.onChangeFirstNote}
                  value={this.props.piano.first_note}
                >
                  {MidiNumbers.NATURAL_MIDI_NUMBERS.map((midiNumber: number) => (
                    <option
                      value={midiNumber}
                      disabled={midiNumber >= this.props.piano.last_note}
                      key={midiNumber}
                    >
                      {midiNumbersToNotes[midiNumber]}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-6">
                <Label>Last note</Label>
                <select
                  className="form-control"
                  onChange={this.onChangeLastNote}
                  value={this.props.piano.last_note}
                >
                  {MidiNumbers.NATURAL_MIDI_NUMBERS.map((midiNumber: number) => (
                    <option
                      value={midiNumber}
                      disabled={midiNumber <= this.props.piano.first_note}
                      key={midiNumber}
                    >
                      {midiNumbersToNotes[midiNumber]}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 mt-3">
                <Label>Instrument</Label>
                <InstrumentListProvider
                  hostname={SOUNDFONT_HOSTNAME}
                  render={(instrumentList) => (
                    <select
                      className="form-control"
                      value={this.props.piano.instrument_name}
                      onChange={this.onChangeInstrument}
                    >
                      {(instrumentList || [this.props.piano.instrument_name]).map((value) => (
                        <option value={value} key={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  )}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={this.props.toggle}>
              Close
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default KeyboardSettings;
