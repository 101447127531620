import React from 'react';
import { ControlledPiano } from 'react-piano';
import { KeyboardShortcutType, NoteRange } from 'types';

export type PianoProps = {
  activeNotes?: number[] | null;
  className?: string;
  disabled?: boolean;
  keyboardShortcuts?: KeyboardShortcutType[] | null;
  keyWidthToHeight?: number;
  noteRange: NoteRange;
  onPlayNoteInput?: (midiNumber: number) => void;
  onStopNoteInput?: (midiNumber: number) => void;
  playNote: (midiNumber: number) => void;
  stopNote: (midiNumber: number) => void;
};

function Piano(props: PianoProps) {
  return <ControlledPiano {...props} />;
}

export default Piano;
