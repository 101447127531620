import React from 'react';
import Dimensions from 'react-dimensions';

type DimensionsInjectedProps = {
  containerWidth: number;
  containerHeight: number;
};

class DimensionsProvider extends React.Component<
  DimensionsInjectedProps & {
    children: ({
      containerWidth,
      containerHeight,
    }: {
      containerWidth: number;
      containerHeight: number;
    }) => React.ReactNode;
    className?: string;
  }
> {
  render() {
    return (
      <div className={this.props.className}>
        {this.props.children({
          containerWidth: this.props.containerWidth,
          containerHeight: this.props.containerHeight,
        })}
      </div>
    );
  }
}

export default Dimensions()(DimensionsProvider);
