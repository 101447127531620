import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MidiNumbers } from 'react-piano';
import shortid from 'shortid';

import BasePiano from 'components/BasePiano';
import DimensionsProvider from 'components/DimensionsProvider';
import Nav from 'components/Nav';
import SoundfontProvider from 'components/SoundfontProvider';
import { PianoType, RecordingType, PendingNotesType, EventType } from 'types';
import { SOUNDFONT_HOSTNAME, audioContext } from 'utils/audio';
import pianoActions from 'utils/pianoActions';
import { DEFAULT_RECORDING, PlaybackState, playRecording } from 'utils/recording';

import ActionMenu from './ActionMenu';
import Controls from './Controls';
import KeyboardShortcutsProvider from './KeyboardShortcutsProvider';
import RecordingPiano from './RecordingPiano';
import PianoRoll from './PianoRoll';
import TutorialOverlay from './TutorialOverlay';
import { Mode } from './types';

type RouteParams = {
  shortID: string;
};

type Props = RouteComponentProps<RouteParams> & {};

type State = {
  activeNotes: number[];
  keyEventsEnabled: boolean;
  mode: Mode;
  pendingNotes: PendingNotesType;
  piano: PianoType;
  playbackState: PlaybackState | null;
  recording: RecordingType;
  recordingStartTime: number | null;
  stopAllNotes: () => void;
};

// This component renders the Piano recording and editing
// interface, which includes a keyboard, piano roll, and
// playback controls.
class PianoEditor extends React.Component<Props, State> {
  state: State = {
    piano: {
      short_id: shortid.generate(),
      title: 'Untitled',
      first_note: MidiNumbers.fromNote('c2'),
      last_note: MidiNumbers.fromNote('c8'),
      instrument_name: 'acoustic_grand_piano',
      key_width_to_height: 0.4,
    },
    recording: DEFAULT_RECORDING,
    // Whether editor is in recording, stopped, or playback mode
    mode: Mode.STOPPED,
    // Used to determine Piano activeNotes for playback
    activeNotes: [],
    // Notes that are pressed down but not yet committed to recording
    pendingNotes: {},
    // In recording mode, the window.performance.now() timestamp of when recording started
    recordingStartTime: null,
    // Metadata object relating to playback
    playbackState: null,
    // Function to stop all notes
    stopAllNotes: () => {},
    // Whether to enable/disable keyboard shortcuts
    // TODO: make this global redux state
    keyEventsEnabled: true,
  };

  componentDidMount() {
    const shortID = this.getShortID();
    if (shortID) {
      pianoActions
        .fetch(shortID)
        .then(({ piano, recording }) => {
          this.setState({
            piano,
          });
          this.setRecording(recording || DEFAULT_RECORDING);
        })
        .catch((error) => {
          // TODO: throw error and add error boundary
          console.error('PianoEditor fetch error', error);
        });
    }
    window.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  /*
   * Piano loading and saving
   */
  getShortID = () => {
    return this.props.match.params.shortID;
  };

  onSave = () => {
    if (this.getShortID()) {
      // Piano already exists - update it
      pianoActions.update({
        piano: this.state.piano,
        recording: this.state.recording,
      });
    } else {
      // Piano doesn't exist yet - create it, and then redirect
      // to the piano's URL.
      pianoActions
        .create({
          piano: this.state.piano,
          recording: this.state.recording,
        })
        .then((response) => {
          this.props.history.push(`/song/${this.state.piano.short_id}`);
        });
    }
  };

  setRecording = (value: Partial<RecordingType>) => {
    this.setState({
      recording: Object.assign({}, this.state.recording, value),
    });
  };

  setPiano = (value: Partial<PianoType>) => {
    this.setState({
      piano: Object.assign({}, this.state.piano, value),
    });
  };

  setKeyEventsEnabled = (value: boolean) => {
    this.setState({
      keyEventsEnabled: value,
    });
  };

  setPendingNotes = (value: PendingNotesType) => {
    if (this.state.mode !== Mode.RECORDING) {
      return;
    }
    this.setState({
      pendingNotes: value,
    });
  };

  handleMidiEvent = (midiNumber: number, eventType: 'start' | 'stop') => {
    this.setState((prevState: State) => {
      const { activeNotes } = prevState;

      if (eventType === 'start') {
        if (activeNotes.includes(midiNumber)) {
          return null;
        }
        return {
          ...prevState,
          activeNotes: activeNotes.concat(midiNumber),
        };
      } else if (eventType === 'stop') {
        return {
          ...prevState,
          activeNotes: activeNotes.filter((note) => midiNumber !== note),
        };
      }
    });
  };

  /*
   * Playback and recording methods
   */
  isPlaying = () => {
    return this.state.mode === Mode.PLAYING;
  };

  isRecording = () => {
    return this.state.mode === Mode.RECORDING;
  };

  isStopped = () => {
    return this.state.mode === Mode.STOPPED;
  };

  onPlay = () => {
    if (this.state.mode !== Mode.STOPPED) {
      console.warn('onPlay called from non-stopped mode');
      return;
    }
    this.setState({
      mode: Mode.PLAYING,
      playbackState: playRecording({
        recording: this.state.recording,
        setActiveNotes: (activeNotes) => {
          this.setState({ activeNotes });
        },
        stopAllNotes: this.state.stopAllNotes,
        onEnd: () => this.onStop(),
      }),
    });
  };

  onStop = () => {
    // Cancel any outstanding scheduled setTimeouts
    if (this.state.playbackState) {
      this.state.playbackState.stop();
    }

    if (this.state.mode === Mode.RECORDING) {
      if (!this.state.recordingStartTime) {
        throw new Error('recordingStartTime not set in Mode.RECORDING');
      }
      const recordingDuration = window.performance.now() / 1000 - this.state.recordingStartTime;
      const stopTime = this.state.recording.currentTime + recordingDuration;
      const duration = Math.max(this.state.recording.duration, stopTime);

      this.setRecording({
        duration,
        currentTime: stopTime,
      });
    }

    // Switch mode, reset playback
    this.setState({
      mode: Mode.STOPPED,
      playbackState: null,
      recordingStartTime: null,
    });
  };

  onRecord = () => {
    if (this.state.mode !== Mode.STOPPED) {
      console.warn('onRecord called from non-stopped mode');
      return;
    }
    this.setState({
      mode: Mode.RECORDING,
      recordingStartTime: window.performance.now() / 1000,
    });
  };

  recordEvent = (event: EventType) => {
    if (!this.isRecording()) {
      return;
    }
    this.setRecording({
      events: this.state.recording.events.concat(event),
    });
  };

  /*
   * Edits
   */

  deleteAllNotes = () => {
    if (!this.isStopped()) {
      console.warn('deleteAllNotes called in non-stopped mode');
      return;
    }
    if (!window.confirm('This will delete all notes. Are you sure?')) {
      return;
    }
    this.setState({
      mode: Mode.STOPPED,
    });
    this.setRecording({ events: [], currentTime: 0, duration: 0 });
  };

  // Move cursor to beginning of recording
  moveCursorToStart = () => {
    if (!this.isStopped()) {
      return;
    }
    this.setRecording({
      currentTime: 0,
    });
  };

  moveCursorToEnd = () => {
    if (!this.isStopped()) {
      return;
    }
    this.setRecording({
      currentTime: this.state.recording.duration,
    });
  };

  /*
   * Keyboard shortcuts
   */

  onKeyDown = (event: KeyboardEvent) => {
    // Don't register key events if they're disabled
    if (!this.state.keyEventsEnabled) {
      return;
    }
    // Shortcuts
    if (event.key === 'Enter') {
      if (this.isPlaying() || this.isRecording()) {
        this.onStop();
      } else if (this.isStopped()) {
        this.onRecord();
      }
    } else if (event.key === ' ') {
      // Toggle play/stop with spacebar
      if (this.isPlaying() || this.isRecording()) {
        this.onStop();
      } else if (this.isStopped()) {
        this.onPlay();
      }
    } else if (event.key === 's') {
      // Use cmd + s to save
      if (event.metaKey) {
        this.onSave();
        // Prevent default save dialog behavior
        event.preventDefault();
      }
    } else if (event.key === 'Backspace') {
      if (event.shiftKey) {
        this.deleteAllNotes();
      }
    } else if (event.key === 'ArrowUp') {
      if (event.shiftKey) {
        this.moveCursorToEnd();
      }
    } else if (event.key === 'ArrowDown') {
      if (event.shiftKey) {
        this.moveCursorToStart();
      }
    }
  };

  render() {
    // Both playback and recording piano heights are the same
    // because it allows PianoRoll dimensions to stay consistent
    const pianoHeight = 90;
    const noteRange = {
      first: this.state.piano.first_note,
      last: this.state.piano.last_note,
    };
    const isRecordingOrStopped = this.isRecording() || this.isStopped();
    const keyboardShortcutOffset = noteRange.last - noteRange.first > 36 ? 14 : 0; // Shift shortcuts by two octaves

    return (
      <SoundfontProvider
        instrumentName={this.state.piano.instrument_name}
        audioContext={audioContext}
        hostname={SOUNDFONT_HOSTNAME}
        onLoad={({ stopAllNotes }) => this.setState({ stopAllNotes })}
        render={({ isLoading, playNote, stopNote, stopAllNotes }) => (
          <div className="PianoEditor">
            <Nav>
              <Controls
                mode={this.state.mode}
                recording={this.state.recording}
                setKeyEventsEnabled={this.setKeyEventsEnabled}
                piano={this.state.piano}
                setPiano={this.setPiano}
                shortID={this.getShortID()}
                onSave={this.onSave}
                onPlay={this.onPlay}
                onStop={this.onStop}
                onRecord={this.onRecord}
              />
            </Nav>
            {/* Recording piano */}
            {isRecordingOrStopped && (
              <div className="width-full" style={{ height: pianoHeight }}>
                <KeyboardShortcutsProvider
                  noteRange={noteRange}
                  naturalNoteOffset={keyboardShortcutOffset}
                >
                  {(keyboardShortcuts) => (
                    <RecordingPiano
                      recording={this.state.recording}
                      recordEvent={this.recordEvent}
                      recordingStartTime={this.state.recordingStartTime as number}
                      pendingNotes={this.state.pendingNotes}
                      setPendingNotes={this.setPendingNotes}
                      handleMidiEvent={this.handleMidiEvent}
                      // Pass-through props
                      activeNotes={this.state.activeNotes}
                      noteRange={noteRange}
                      playNote={playNote}
                      stopNote={stopNote}
                      disabled={isLoading || !isRecordingOrStopped}
                      keyboardShortcuts={this.state.keyEventsEnabled ? keyboardShortcuts : null}
                      keyWidthToHeight={this.state.piano.key_width_to_height}
                    />
                  )}
                </KeyboardShortcutsProvider>
              </div>
            )}
            <div className="flex-1 position-relative">
              {this.isStopped() && (
                <ActionMenu
                  className="position-absolute bottom-0 right-0 z-index-1 width-7"
                  piano={this.state.piano}
                  setPiano={this.setPiano}
                  recording={this.state.recording}
                  setRecording={this.setRecording}
                  setKeyEventsEnabled={this.setKeyEventsEnabled}
                  actions={{
                    deleteAllNotes: this.deleteAllNotes,
                    moveCursorToStart: this.moveCursorToStart,
                    moveCursorToEnd: this.moveCursorToEnd,
                  }}
                  onSave={this.onSave}
                  disabled={this.isPlaying()}
                />
              )}
              {this.isStopped() && this.state.recording.events.length === 0 && (
                <div className="position-absolute d-flex width-full height-full justify-content-center align-items-center">
                  <TutorialOverlay />
                </div>
              )}
              <div className="d-flex flex-column height-full">
                <DimensionsProvider>
                  {({
                    containerWidth,
                    containerHeight,
                  }: {
                    containerWidth: number;
                    containerHeight: number;
                  }) => (
                    <PianoRoll
                      className="flex-1"
                      mode={this.state.mode}
                      recording={this.state.recording}
                      setRecording={this.setRecording}
                      recordingStartTime={this.state.recordingStartTime as number}
                      playbackState={this.state.playbackState}
                      pendingNotes={this.state.pendingNotes}
                      width={containerWidth}
                      height={containerHeight}
                      noteRange={noteRange}
                    />
                  )}
                </DimensionsProvider>
              </div>
            </div>
            {/* Playback piano */}
            {this.isPlaying() && (
              <div className="width-full" style={{ height: pianoHeight }}>
                <BasePiano
                  className="width-full height-full"
                  activeNotes={this.state.activeNotes}
                  noteRange={noteRange}
                  playNote={playNote}
                  stopNote={stopNote}
                  disabled={isLoading || !this.isPlaying()}
                  keyWidthToHeight={this.state.piano.key_width_to_height}
                />
              </div>
            )}
          </div>
        )}
      />
    );
  }
}

export default withRouter(PianoEditor);
