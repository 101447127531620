import React from 'react';
import { MdFileUpload, MdPlayArrow, MdSave, MdStop } from 'react-icons/md';
import { TiPencil } from 'react-icons/ti';
import classNames from 'classnames';
import ClickableInput from 'components/ClickableInput';

import { Mode } from './types';
import { RecordingType, PianoType } from 'types';

class Controls extends React.Component<{
  className?: string;
  mode: Mode;
  onPlay: () => void;
  onRecord: () => void;
  onSave: () => void;
  onStop: () => void;
  piano: PianoType;
  recording: RecordingType;
  setKeyEventsEnabled: (value: boolean) => void;
  setPiano: (value: Partial<PianoType>) => void;
  shortID: string | null;
}> {
  render() {
    const isPlaying = this.props.mode === Mode.PLAYING;
    const isStopped = this.props.mode === Mode.STOPPED;
    const isRecording = this.props.mode === Mode.RECORDING;
    const hasEvents = this.props.recording.events.length > 0;
    return (
      <div className={classNames('PianoEditor__Controls', this.props.className)}>
        <div className="btn-group">
          <button
            className="btn btn-sm btn-outline-danger uppercase small"
            disabled={isRecording || isPlaying}
            onClick={this.props.onRecord}
            title="Shortcut: [enter]"
          >
            ● Rec
          </button>
        </div>
        <div className="btn-group ml-2">
          {isStopped ? (
            <React.Fragment>
              <button
                className="btn btn-sm btn-link uppercase small"
                disabled={isPlaying || isRecording || !hasEvents}
                onClick={this.props.onPlay}
                title="Shortcut: [spacebar]"
              >
                <MdPlayArrow className="color-text-black icon-1" />{' '}
                <span className="icon-label-1">Play</span>
              </button>
            </React.Fragment>
          ) : (
            <button
              className="btn btn-sm btn-link uppercase small"
              disabled={isStopped}
              onClick={this.props.onStop}
              title="Shortcut: [spacebar]"
            >
              <MdStop className="color-text-black icon-1" />{' '}
              <span className="icon-label-1">Stop</span>
            </button>
          )}
        </div>
        <div className="btn-group">
          <button
            className="btn btn-sm btn-link uppercase small"
            onClick={this.props.onSave}
            title="Shortcut: [cmd + s]"
          >
            <MdSave className="icon-1" /> <span className="icon-label-1">Save</span>
          </button>
          {this.props.shortID ? (
            <a
              href={`/share/${this.props.shortID}`}
              className="btn btn-sm btn-link uppercase small"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MdFileUpload className="icon-1" /> <span className="icon-label-1">Share</span>
            </a>
          ) : null}
        </div>
        <div className="btn-group ml-5">
          <ClickableInput
            value={this.props.piano.title}
            onEdit={() => {
              this.props.setKeyEventsEnabled(false);
            }}
            onChange={(event) => {
              this.props.setPiano({
                title: event.target.value,
              });
            }}
            onSubmit={() => {
              this.props.setKeyEventsEnabled(true);
              this.props.onSave();
            }}
          >
            <span className="cursor-pointer py-2">
              <TiPencil className="mr-2 d-inline-block align-middle" />
              <span className="d-inline-block align-middle" style={{ minWidth: 100 }}>
                {this.props.piano.title}
              </span>{' '}
            </span>
          </ClickableInput>
        </div>
        <div className="d-inline-block float-right" />
      </div>
    );
  }
}

export default Controls;
