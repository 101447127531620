import React from 'react';
import _ from 'lodash';
import BasePiano, { PianoProps } from 'components/BasePiano';
import { PendingNotesType, RecordingType, EventType } from 'types';

import WebMidi from 'webmidi';

type MidiEvent = {
  channel: number;
  note: {
    number: number;
    name: string;
    octave: number;
  };
  rawVelocity: number;
  type: string;
  velocity: number;
};

type State = {
  input: any;
};

class RecordingPiano extends React.Component<
  PianoProps & {
    pendingNotes: PendingNotesType;
    setPendingNotes: (value: PendingNotesType) => void;
    recordEvent: (event: EventType) => void;
    recording: RecordingType;
    recordingStartTime: number;
    handleMidiEvent: (midiNumber: number, eventType: 'start' | 'stop') => void;
  },
  State
> {
  state: State = {
    input: null,
  };

  // TODO: improve typing, remove any's
  // TODO: add instrument selector (WebMidi.inputs)
  componentDidMount() {
    WebMidi.enable((error) => {
      if (error) {
        console.warn('WebMidi could not be enabled.');
        return;
      }
      console.log('WebMidi enabled.');
      if (WebMidi.inputs.length === 0) {
        console.log('No MIDI inputs.');
        return;
      }
      const input = WebMidi.inputs[0];
      input.addListener('noteon', 'all', (event: MidiEvent) => {
        this.onPlayNoteInput(event.note.number);
      });
      input.addListener('noteoff', 'all', (event: MidiEvent) => {
        this.onStopNoteInput(event.note.number);
      });
      this.setState({
        input,
      });
    });
  }

  componentWillUnmount() {
    const { input } = this.state;
    if (input) {
      input.removeListener();
    }
  }

  onPlayNoteInput = (midiNumber: number) => {
    const { handleMidiEvent, pendingNotes, setPendingNotes } = this.props;
    handleMidiEvent(midiNumber, 'start');

    if (!pendingNotes[midiNumber]) {
      setPendingNotes(
        Object.assign({}, pendingNotes, {
          // Start time of note
          [midiNumber]: window.performance.now() / 1000,
        }),
      );
    }
  };

  onStopNoteInput = (midiNumber: number) => {
    const {
      handleMidiEvent,
      pendingNotes,
      setPendingNotes,
      recordingStartTime,
      recording,
      recordEvent,
    } = this.props;
    handleMidiEvent(midiNumber, 'stop');

    const absoluteStartTime = pendingNotes[midiNumber];
    if (!absoluteStartTime) {
      console.warn(`Unexpected no start time for ${midiNumber}`);
      return;
    }
    const absoluteEndTime = window.performance.now() / 1000;
    const duration = absoluteEndTime - absoluteStartTime;
    const relativeStartTime = absoluteStartTime - recordingStartTime + recording.currentTime;

    recordEvent({ midiNumber, time: relativeStartTime, duration });
    setPendingNotes(_.omit(pendingNotes, midiNumber));
  };

  render() {
    const {
      pendingNotes,
      setPendingNotes,
      recording,
      recordEvent,
      recordingStartTime,
      handleMidiEvent,
      ...pianoProps
    } = this.props;

    return (
      <BasePiano
        className="width-full height-full"
        onPlayNoteInput={this.onPlayNoteInput}
        onStopNoteInput={this.onStopNoteInput}
        {...pianoProps}
      />
    );
  }
}

export default RecordingPiano;
