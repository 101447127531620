import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import axios from 'axios';
import { connect } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { MdUp, SmDown } from 'components/Responsive';
import { UserType } from 'types';

class Nav extends React.Component<{
  className?: string;
  dispatch: Function;
  user: UserType;
}> {
  handleLogout = () => {
    axios.post('/api/auth/logout').then((response) => {
      this.props.dispatch({
        type: 'SET_USER',
        item: null,
      });
    });
  };

  render() {
    const { className, children, user } = this.props;
    return (
      <div className={classNames('Nav px-4 py-3', className)}>
        <div className="d-flex align-items-center">
          <div>
            <Link className="Nav__logo" to="/">
              <span className="font-weight-700">Piano</span>
              <span className="font-weight-500">Hub</span>
            </Link>
          </div>
          <div className="ml-6 flex-1">
            <MdUp>{children}</MdUp>
          </div>
          <div className="d-flex align-items-center">
            {this.props.user ? (
              <div className="ml-2">
                <LoggedInMenu user={user} onLogout={this.handleLogout} />
              </div>
            ) : (
              <React.Fragment>
                {/* TODO: temporarily disabled
                  <Link className="btn btn-sm btn-link ml-2" to="/login">
                    Login
                  </Link>
                  <Link className="btn btn-sm btn-link ml-2" to="/signup">
                    Sign up
                  </Link>
                */}
              </React.Fragment>
            )}
            <a href="/song" className="ml-2 btn btn-sm btn-outline-primary">
              New song
            </a>
          </div>
        </div>
        <SmDown>
          <div className="mt-3">{children}</div>
        </SmDown>
      </div>
    );
  }
}

type LoggedInMenuProps = {
  user: UserType;
  onLogout: () => void;
};

type LoggedInMenuState = {
  isOpen: boolean;
};

class LoggedInMenu extends React.Component<LoggedInMenuProps, LoggedInMenuState> {
  state = {
    isOpen: false,
  };

  toggleIsOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this.toggleIsOpen} size="sm">
        <DropdownToggle caret>
          <span>{this.props.user.username}</span>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={this.props.onLogout}>Logout</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const ExportedNav: React.ComponentType<{ className?: string }> = connect((state: any) => {
  return {
    user: state.user.item,
  };
})(Nav);

export default ExportedNav;
