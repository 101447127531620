import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import axios from 'axios';

import Nav from 'components/Nav';
import { EmailField, PasswordField } from './formFields';

export interface LoginFormValues {
  email: string;
  password: string;
}

function buildErrors(responseError: string) {
  let errors: FormikErrors<LoginFormValues> = {};
  if (responseError === 'MISSING_EMAIL') {
    errors.email = 'Email required';
  } else if (responseError === 'USER_DOES_NOT_EXIST') {
    errors.email = 'Email does not exist';
  } else if (responseError === 'MISSING_PASSWORD') {
    errors.password = 'Password required';
  } else if (responseError === 'INCORRECT_CREDENTIALS') {
    errors.password = 'Incorrect password';
  }
  return errors;
}

class Login extends React.Component<
  RouteComponentProps & {
    dispatch: Function;
  }
> {
  render() {
    return (
      <div>
        <Nav />
        <div className="container my-6">
          <div className="row">
            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
              <h2>
                <span role="img" aria-label="key">
                  🔑
                </span>{' '}
                Login to PianoHub
              </h2>
              <LoginForm
                className="mt-5"
                history={this.props.history}
                dispatch={this.props.dispatch}
              />
              <div className="mt-5">
                <Link className="text-secondary" to="/signup">
                  Don’t have an account? <strong>Sign up for free.</strong>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class LoginForm extends React.Component<{
  className?: string;
  dispatch: Function;
  history: RouteComponentProps['history'];
}> {
  render() {
    return (
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={(values: LoginFormValues, actions: FormikActions<LoginFormValues>) => {
          axios
            .post('/api/auth/login', values)
            .then(({ data }) => {
              // TODO: setstate on unmounted component issue, use <Redirect> method instead?
              this.props.dispatch({
                type: 'SET_USER',
                item: data.data,
              });
              this.props.history.push('/');
            })
            .catch(({ response }) => {
              console.error('Login error', response);
              actions.setErrors(buildErrors(response.data.error));
            })
            .then(() => {
              actions.setSubmitting(false);
            });
        }}
        render={(formikProps: FormikProps<LoginFormValues>) => {
          return (
            <form className={this.props.className} onSubmit={formikProps.handleSubmit}>
              <EmailField name="email" {...formikProps} />
              <PasswordField name="password" label="Password" {...formikProps} />
              <div className="mt-4">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={formikProps.isSubmitting}
                >
                  Submit
                </button>
              </div>
            </form>
          );
        }}
      />
    );
  }
}

export default connect(null)(Login);
