// Functions around manipulating 'recording' data structure.
import _ from 'lodash';

import { EventType, RecordingType } from 'types';

export type PlaybackState = {
  duration: number;
  playbackStartTime: number;
  playbackEndTime: number;
  stop: () => void;
};

export const DEFAULT_RECORDING: RecordingType = {
  currentTime: 0,
  duration: 0,
  events: [],
  bpm: 300,
  version: '2018-03-03',
};

export function getStartTime(events: EventType[]): number {
  if (events.length === 0) {
    return 0;
  }
  return Math.min(...events.map((event) => event.time));
}

export function formatDuration(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.round(seconds) % 60;
  return `${minutes}:${String(remainingSeconds).padStart(2, '0')}`;
}

// Groups events into arrays by start/end time.
// This acts to (1) essentially turn a time/duration pair into a start/stop event pair,
// and (2) to group events starting at the same time together (i.e. chords).
//
// Example:
//
// groupEventsByTime([{ time: 0, duration: 1, midiNumber: 66 }, { time: 2, duration: 1, midiNumber: 70 }]
// =>
// [
//   { time: 0, events: [{ midiNumber: 66, ...}] },  // Start playing 66 at time 0
//   { time: 1, events: [] },                        // Stop playing 66 at time 1
//   { time: 2, events: [{ midiNumber: 70, ...}] }   // Start playing 70 at time 2
// ]
export function groupEventsByTime(events: EventType[]): { time: number; events: EventType[] }[] {
  const times = _.uniq(
    // Map each event to start and end time
    _.flatMap(events, (event) => [event.time, event.time + event.duration]),
  ).sort();
  return times.map((time) => {
    return {
      time,
      events: events.filter((event) => {
        return event.time <= time && event.time + event.duration > time;
      }),
    };
  });
}

// Construct an array of midiNumbers from a set of events.
// If there are no events, returns null instead of [] because Piano.activeNotes being []
// prevents recording.
export function getActiveNotesFromEvents(events: EventType[]): number[] {
  return events.map((event) => event.midiNumber);
}

export function getLastNoteEndTime(recording: RecordingType): number {
  if (recording.events.length === 0) {
    return 0;
  }
  return Math.max(...recording.events.map((event) => event.time + event.duration));
}

export function shiftEvents(events: EventType[], time: number): EventType[] {
  return events.map((event) => Object.assign({}, event, { time: event.time + time }));
}

// When cursor (i.e. currentTime) is in the middle of the recording,
// start 'playing back' from that time. However, if currentTime is at the END
// of the recording, just play it back from the beginning.
// Consider the END of recording to be any space past the last note's ending.
export function getPlaybackTimeOffset(recording: RecordingType): number {
  const lastNoteEndTime = getLastNoteEndTime(recording);
  return recording.currentTime > lastNoteEndTime ? 0 : recording.currentTime;
}

// Set Piano activeNotes over time using setTimeout
// in order to play the recording.
export function playRecording({
  recording,
  setActiveNotes,
  stopAllNotes,
  onStart,
  onEnd,
}: {
  recording: RecordingType;
  setActiveNotes: (activeNotes: number[]) => void;
  stopAllNotes: () => void;
  onStart?: () => void;
  onEnd?: () => void;
}): PlaybackState {
  if (onStart) {
    onStart();
  }

  const timeOffset = getPlaybackTimeOffset(recording);
  const eventsToPlay = shiftEvents(
    recording.events.filter((event) => event.time >= timeOffset),
    -timeOffset,
  );

  const playbackDuration = recording.duration - timeOffset;
  const playbackStartTime = window.performance.now() / 1000;
  const playbackEndTime = playbackStartTime + playbackDuration;
  let scheduledEvents: number[] = [];

  // Iterate through all events, and at each discrete point in
  // time in which the events change (either adding or decreasing notes), set
  // activeNotes at that time so the piano plays it accordingly.
  groupEventsByTime(eventsToPlay).forEach(({ time, events }) => {
    scheduledEvents.push(
      // Schedule playing activeNotes at each point in time
      window.setTimeout(() => {
        setActiveNotes(getActiveNotesFromEvents(events));
      }, time * 1000),
    );
  });

  // Schedule call to onEnd at the end of duration
  if (onEnd) {
    scheduledEvents.push(window.setTimeout(onEnd, playbackDuration * 1000));
  }

  return {
    duration: playbackDuration,
    playbackStartTime,
    playbackEndTime,
    stop: () => {
      scheduledEvents.forEach((scheduledEvent) => {
        window.clearTimeout(scheduledEvent);
      });
      // Needed to emit stop signal to notes that were started,
      // otherwise they linger.
      stopAllNotes();
      // Clear Piano activeNotes
      setActiveNotes([]);
    },
  };
}
